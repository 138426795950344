<template>
  <div class="w-full h-full pt-5">
    <loader size="xxs" :loader-image="false" v-if="isLoading" />
    <template v-else>
      <div class="flex mb-10 px-5">
        <h1 class="text-xl text-left font-extrabold mr-5">Overview</h1>
        <breadcrumb :items="breadcrumbs" />
      </div>

      <div class="flex flex-col gap-5">
        <div class="mx-5 rounded-md flex justify-between gap-5">
          <card class="flex flex-col justify-start items-start p-5 gap-5 w-3/5">
            <div class="w-full flex flex-col gap-3 relative">
              <div class="flex flex-col justify-start items-start gap-0">
                <div class="flex w-full justify-between items-center gap-0">
                  <p class="font-bold text-darkPurple flex flex-grow text-lg">
                    Pay Items
                  </p>
                  <card
                    class="w-44 h-11 px-3 flex items-center text-base text-darkPurple"
                  >
                    <c-select
                      class="w-full select-css"
                      variant="rm-border"
                      v-model="currentYear"
                      :options="optionsYear"
                      @input="selectItemYear($event)"
                    />
                  </card>
                </div>
                <template v-if="loadingPayItem">
                  <div style="height: 300px" class="w-full">
                    <loader size="xxs" :loader-image="false" />
                  </div>
                </template>
                <template v-else>
                  <div
                    class="flex flex-col justify-center my-7 w-full items-center"
                    v-if="payItemsLabel.length > 0"
                  >
                    <div class="flex w-full justify-center items-start">
                      <DonutChart
                        :data="payItemsDataObj"
                        :options="chartOptions"
                        :styles="styles"
                        :value="totalPayItems"
                      />
                    </div>
                    <div class="w-full mt-2 flex flex-wrap justify-center">
                      <div
                        class="flex ml-4 my-2"
                        v-for="(legend, index) in payItemsLegend"
                        :key="index"
                      >
                        <div
                          class="rounded-full mr-2 mt-1"
                          :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                        />
                        <div class="text-sm text-romanSilver">
                          {{ legend.name }} : {{ legend.data }}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-col w-full mb-5 items-center" v-else>
                    <Icon
                      icon-name="dashboard-error"
                      class="-mt-1 iconSize"
                      style="width: 350px; height: 350px; margin: 0 auto"
                    />
                    <p class="text-xl font-bold">No Data Available</p>
                    <p class="text-xs text-romanSilver">
                      There is no data to show currently.
                    </p>
                  </div>
                </template>
              </div>
            </div>
          </card>

          <card class="flex flex-col justify-start items-start p-5 gap-5 w-3/5">
            <div class="w-full flex flex-col gap-3 relative">
              <div class="flex flex-col justify-start items-start gap-0">
                <div class="flex w-full justify-between items-center gap-0">
                  <p class="font-bold text-darkPurple flex flex-grow text-lg">
                    Payroll Type
                  </p>
                  <card
                    class="flex justify-evenly items-center gap-1 mx-2 px-2 h-11 relative"
                  >
                    <div class="w-1/7 pr-2 border-r border-darkPurple">
                      <Icon icon-name="calendar" size="xs" />
                    </div>
                    <div class="w-5/7 flex">
                      <c-select
                        class="w-full select-css"
                        variant="rm-border"
                        v-model="payrollType.month"
                        :options="optionsMonth"
                        @input="selectTypeMonth($event)"
                      />
                    </div>
                  </card>
                  <card
                    class="flex justify-evenly items-center gap-1 px-2 h-11 relative"
                  >
                    <div class="w-auto flex">
                      <c-select
                        class="w-full select-css"
                        variant="rm-border"
                        v-model="payrollType.year"
                        :options="optionsYear"
                        @input="selectTypeYear($event)"
                      />
                    </div>
                  </card>
                </div>
                <div class="flex flex-col w-full justify-end gap-2">
                  <template v-if="loadingPayType">
                    <div style="height: 300px" class="w-full">
                      <loader size="xxs" :loader-image="false" />
                    </div>
                  </template>
                  <template v-else>
                    <div
                      class="flex flex-col justify-center mt-10 items-start"
                      v-if="chartLabels.length > 0"
                    >
                      <div class="flex w-full justify-center items-start">
                        <DonutChart
                          :data="chartDataObject"
                          :options="chartOptions"
                          :styles="styles"
                        />
                      </div>
                      <div class="flex w-full justify-center my-3">
                        <span class="uppercase font-black text-sm">
                          Total Cost per Month
                        </span>
                        <span class="text-sm font-black text-blueCrayola mx-2">
                          {{ totalPayType }}
                        </span>
                      </div>
                      <div class="w-full mt-2 flex flex-col justify-center">
                        <div
                          class="flex ml-4 my-2"
                          v-for="(legend, index) in chartLegend"
                          :key="index"
                        >
                          <div
                            class="rounded-full mr-2 mt-1"
                            :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                          />
                          <div class="text-sm text-romanSilver">
                            {{ legend.name }} : (Cost = {{ legend.data }} , Employees = {{ legend.numberEmployees }})
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="flex flex-col w-full mb-5 items-center" v-else>
                      <Icon
                        icon-name="dashboard-error"
                        class="-mt-1 iconSize"
                        style="width: 350px; height: 350px; margin: 0 auto"
                      />
                      <p class="text-xl font-bold">No Data Available</p>
                      <p class="text-xs text-romanSilver">
                        There is no data to show currently.
                      </p>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </card>
        </div>

        <div class="mx-5 flex justify-between gap-5">
          <card
            class="p-5 flex flex-col justify-between items-start gap-5 w-full"
          >
            <div class="w-full flex flex-col gap-3">
              <div class="flex justify-between items-center gap-0">
                <p class="font-bold text-darkPurple text-lg">Payroll Cost</p>
                <div
                  class="w-44 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    v-model="payrollCost.year"
                    :options="optionsYear"
                    @input="selectCostYear($event)"
                  />
                </div>
              </div>
              <div class="w-full flex items-center my-1">
                <div class="flex flex-grow rounded h-10">
                  <div class="flex bg-ghostWhite -mt-2"></div>
                </div>
                <card
                  class="w-56 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    placeholder="--Select--"
                    v-model="payrollCost.type"
                    @input="selectCostOptions($event)"
                    :options="optionTags"
                  />

                  <div @click="filterView('payrollCost')">
                    <Icon
                      class-name="text-darkPurple h-full mx-auto ml-10 mt-1 self-center mr-2 cursor-pointer"
                      size="xs"
                      icon-name="sort_size"
                    />
                  </div>
                </card>
                <div
                  style="background-color: #f0f4fe"
                  class="p-2 mx-3 rounded-sm"
                >
                  <span class="font-black text-base"> {{ totalCost }} </span>
                </div>
              </div>
            </div>
            <template v-if="loadingPayCost">
              <div style="height: 300px" class="w-full">
                <loader size="xxs" :loader-image="false" />
              </div>
            </template>
            <template v-else>
              <div
                class="flex w-full flex-col bg-ghostWhite justify-center my-1 rounded-sm items-start"
                v-if="costLabels.length > 0"
              >
                <div class="flex w-full justify-center items-start p-10">
                  <BarChart
                    class="flex flex-col justify-between"
                    :options="costChartOptions"
                    :data="costChartData"
                    :styles="{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }"
                  />
                </div>
              </div>

              <div class="flex flex-col w-full mb-5 items-center" v-else>
                <Icon
                  icon-name="dashboard-error"
                  class="-mt-1 iconSize"
                  style="width: 350px; height: 350px; margin: 0 auto"
                />
                <p class="text-xl font-bold">No Data Available</p>
                <p class="text-xs text-romanSilver">
                  There is no data to show currently.
                </p>
              </div>
            </template>
          </card>
        </div>

        <div class="mx-5 flex justify-between gap-5">
          <card
            class="p-5 flex flex-col justify-between items-start gap-5 w-full"
          >
            <div class="w-full flex flex-col gap-3">
              <div class="flex justify-between items-center gap-0">
                <p class="font-bold text-darkPurple text-lg">Gender Pay Gap</p>
                <div
                  class="w-44 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    v-model="genderPayGap.year"
                    :options="optionsYear"
                    @input="selectGenderPayGapYear($event)"
                  />
                </div>
              </div>
              <div class="w-full flex items-center my-1">
                <div class="flex flex-grow rounded h-10">
                  <div class="flex bg-ghostWhite -mt-2"></div>
                </div>
                <card
                  class="w-56 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    placeholder="--Select--"
                    v-model="genderPayGap.type"
                    @input="selectGenderPayGapOptions($event)"
                    :options="payGapTags"
                  />
                  <div @click="filterView('genderPayGap')">
                    <Icon
                      class-name="text-darkPurple h-full mx-auto ml-6 mt-1 self-center mr-2 cursor-pointer"
                      size="xs"
                      icon-name="sort_size"
                    />
                  </div>
                </card>
                <div
                  style="background-color: #f0f4fe"
                  class="p-2 mx-3 rounded-sm"
                >
                  <span class="font-black text-base">
                    {{ convertToCurrency(totalPayGap) }}
                  </span>
                </div>
              </div>
            </div>
            <template v-if="loadingGenderPay">
              <div style="height: 300px" class="w-full">
                <loader size="xxs" :loader-image="false" />
              </div>
            </template>
            <template v-else>
              <div
                class="flex w-full flex-col bg-ghostWhite justify-center my-1 rounded-sm items-start"
                v-if="genderPayGapLabels.length > 0"
              >
                <div class="flex w-full justify-center items-start p-10">
                  <BarChart
                    class="flex flex-col justify-between"
                    :options="costChartOptions"
                    :data="genderPayGapDataObj"
                    :styles="{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }"
                  />
                </div>
                <div class="w-full mt-2 flex flex-wrap justify-center">
                      <div
                        class="flex ml-4 my-2"
                        v-for="(legend, index) in genderPayGapLegend"
                        :key="index"
                      >
                        <div
                          class="rounded-full mr-2 mt-1"
                          :style="`width: 12px; height: 12px; background-color: ${legend.color}`"
                        />
                        <div class="text-sm font-extrabold">
                          {{ legend.name }}
                        </div>
                      </div>
                    </div>
              </div>

              <div class="flex flex-col w-full mb-5 items-center" v-else>
                <Icon
                  icon-name="dashboard-error"
                  class="-mt-1 iconSize"
                  style="width: 350px; height: 350px; margin: 0 auto"
                />
                <p class="text-xl font-bold">No Data Available</p>
                <p class="text-xs text-romanSilver">
                  There is no data to show currently.
                </p>
              </div>
            </template>
          </card>
        </div>

        <div class="mx-5 flex justify-between gap-5">
          <card
            class="p-5 flex flex-col justify-between items-start gap-5 w-full"
          >
            <div class="w-full flex flex-col gap-3">
              <div class="flex justify-between items-center gap-0">
                <p class="font-bold text-darkPurple text-lg">Off Cycle Pay</p>
                <div
                  class="w-56 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    v-model="offCyclePay.year"
                    :options="optionsYear"
                    @input="selectOffCyclePayYear($event)"
                  />
                </div>
              </div>
              <div class="w-full flex items-center my-1">
                <div class="flex flex-grow rounded h-10">
                  <div class="flex bg-ghostWhite -mt-2"></div>
                </div>
                <card
                  class="w-56 h-11 px-3 flex items-center text-base text-darkPurple"
                >
                  <c-select
                    class="w-full select-css"
                    variant="rm-border"
                    placeholder="--Select--"
                    v-model="offCyclePay.type"
                    @input="selectOffCyclePayOptions($event)"
                    :options="offCycleTags"
                  />
                  <div @click="filterView('offCyclePay')">
                    <Icon
                      class-name="text-darkPurple h-full mx-auto ml-6 mt-1 self-center mr-2 cursor-pointer"
                      size="xs"
                      icon-name="sort_size"
                    />
                  </div>
                </card>
                <div
                  style="background-color: #f0f4fe"
                  class="p-2 mx-3 rounded-sm"
                >
                  <span class="font-black text-base">
                    {{ convertToCurrency(totalOffCycleData) }}
                  </span>
                </div>
              </div>
            </div>
            <template v-if="loadingOffCyclePay">
              <div style="height: 300px" class="w-full">
                <loader size="xxs" :loader-image="false" />
              </div>
            </template>
            <template v-else>
              <div
                class="flex w-full flex-col bg-ghostWhite justify-center my-1 rounded-sm items-start"
                v-if="offCyclePayLabels.length > 0"
              >
                <div class="flex w-full justify-center items-start p-10">
                  <BarChart
                    class="flex flex-col justify-between"
                    :options="costChartOptions"
                    :data="offCyclePayDataObj"
                    :styles="{
                      height: '100%',
                      width: '100%',
                      position: 'relative',
                    }"
                  />
                </div>
              </div>

              <div class="flex flex-col w-full mb-5 items-center" v-else>
                <Icon
                  icon-name="dashboard-error"
                  class="-mt-1 iconSize"
                  style="width: 350px; height: 350px; margin: 0 auto"
                />
                <p class="text-xl font-bold">No Data Available</p>
                <p class="text-xs text-romanSilver">
                  There is no data to show currently.
                </p>
              </div>
            </template>
          </card>
        </div>
      </div>
    </template>

    <FilterTable
      v-if="filterTable"
      :filter-data="filterArray"
      @close="closeFilter($event)"
      @getParams="searchFilter($event)"
    />
  </div>
</template>

<script>
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import DonutChart from "@/components/donutChart";
import BarChart from "@/components/BarChart";
import FilterTable from "@/components/FilterTable";

export default {
  components: {
    Breadcrumb,
    Card,
    CSelect,
    DonutChart,
    FilterTable,
    BarChart,
  },
  data() {
    return {
      filterTable: false,
      payrollType: {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
      },
      payrollCost: {
        year: new Date().getFullYear(),
        type: "allCost",
      },
      offCyclePay: {
        year: new Date().getFullYear(),
        type: "upfrontpay",
      },
      genderPayGap: {
        year: new Date().getFullYear(),
        type: "salary",
      },
      selectedTag: "allCost",
      genderSearch: "",
      optionTags: [
        {
          id: "allCost",
          name: "All Cost",
        },
        {
          id: "salary",
          name: "Salary",
        },
        {
          id: "paye",
          name: "PAYE",
        },
        {
          id: "pension",
          name: "Pension",
        },
        {
          id: "nhf",
          name: "NHF",
        },
      ],
      offCycleTags: [
        {
          id: "upfrontpay",
          name: "Upfront Pay",
        },
        {
          id: "terminal pay",
          name: "Terminal Pay",
        },
        {
          id: "13thmonth",
          name: "13th Month",
        },
        {
          id: "backpay",
          name: "Back Pay"
        }
      ],
      payGapTags: [
        {
          id: "salary",
          name: "Salary",
        },
        {
          id: "bonus",
          name: "Bonus",
        },
        {
          id: "commission",
          name: "Commission",
        },
      ],
      chartDataObject: {},
      totalPayType: "",
      chartLabels: [],
      chartLegend: [],
      chartData: [],
      chartOptions: {
        legend: {
          display: false,
          position: "bottom",
        },
        responsive: true,
        maintainAspectRatio: false,
      },
      styles: {
        height: `300px`,
        width: `300px`,
        position: "relative",
      },
      isLoading: false,
      loadingPayItem: true,
      loadingPayType: true,
      loadingPayCost: true,
      loadingOffCyclePay: true,
      loadingGenderPay: true,
      currentYear: new Date().getFullYear(),
      optionsYear: [],
      filterArray: [
        { header: "Job Category(s)", value: "categoryId", optionsBox: [] },
        { header: "Function(s)", value: "functionId", optionsBox: [] },
        { header: "Location(s)", value: "officeId", optionsBox: [] },
        { header: "Job Level(s)", value: "employeeLevelId", optionsBox: [] },
        { header: "Designation(s)", value: "designationId", optionsBox: [] },
      ],
      optionsMonth: [
        {
          id: 1,
          name: "January",
        },
        {
          id: 2,
          name: "February",
        },
        {
          id: 3,
          name: "March",
        },
        {
          id: 4,
          name: "April",
        },
        {
          id: 5,
          name: "May",
        },
        {
          id: 6,
          name: "June",
        },
        {
          id: 7,
          name: "July",
        },
        {
          id: 8,
          name: "August",
        },
        {
          id: 9,
          name: "September",
        },
        {
          id: 10,
          name: "October",
        },
        {
          id: 11,
          name: "November",
        },
        {
          id: 12,
          name: "December",
        },
      ],
      costChartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { display: false },
        scales: {
          xAxes: [
            { ticks: { beginAtZero: true }, gridLines: { display: false } },
          ],
          yAxes: [
            {
              scaleLabel: { display: true, labelString: "Total Cost (₦)" },
              gridLines: {
                display: true,
                color: "#878E99",
                borderDash: [4, 4],
              },
            },
          ],
        },
      },
      totalCost: "",
      employeesCount: [],
      costChartData: {},
      costLabels: [],
      costData: [],
      query: "",
      itemQuery: "",
      payItemsLabel: [],
      payItemsLegend: [],
      payItemsData: [],
      payItemsDataObj: {},
      totalPayItems: "",
      costQuery: "",
      offCyclePayLabels: [],
      offCyclePayLegend: [],
      offCyclePayData: [],
      offCyclePayDataObj: {},
      totalOffCycleData: "",
      offCycleQuery: "",
      genderPayGapLabels: [],
      genderPayGapLegend: [
        {
          name: 'Male',
          color: '#F15A29'
        },
        {
          name: 'Female',
          color: '#2176FF'
        }
      ],
      genderPayGapData: [],
      genderPayGapDataObj: {},
      totalPayGap: "",
      genderPayGapQuery: "",
      breadcrumbs: [
        { disabled: false, text: "Payroll", href: "Payroll", id: "payroll" },
        { disabled: false, text: "Overview", href: "Overview", id: "Overview" },
      ],
      queryType: "",
    };
  },

  watch: {
    genderSearch(value) {
      const params = `&search=${value}`;
      this.getGenderPayGap(params);
    },
  },

  created() {
    this.query = `?month=${this.payrollType.month}&year=${this.payrollType.year}`;
    this.costQuery = `?year=${this.payrollCost.year}&type=${this.payrollCost.type}`;
    this.offCycleQuery = `?year=${this.offCyclePay.year}&filter=${this.offCyclePay.type}`;
    this.itemQuery = `?year=${this.currentYear}`;
    this.genderPayGapQuery = `?year=${this.genderPayGap.year}&type=${this.genderPayGap.type}`;
  },

  methods: {
    selectItemYear(value) {
      this.currentYear = value;
      this.itemQuery = `?year=${value}`;
      this.getPayItems();
    },
    selectTypeMonth(value) {
      this.payrollType.month = value;
      this.query = `?month=${value}&year=${this.payrollType.year}`;
      this.payType();
    },
    selectTypeYear(year) {
      this.payrollType.year = year;
      this.query = `?month=${this.payrollType.month}&year=${year}`;
      this.payType();
    },
    selectCostYear(year) {
      this.payrollCost.year = year;
      this.costQuery = `?year=${year}&type=${this.payrollCost.type}`;
      this.payCosts();
    },
    selectCostOptions(value) {
      this.payrollCost.type = value;
      this.costQuery = `?year=${this.payrollCost.year}&type=${value}`;
      this.payCosts();
    },
    selectOffCyclePayYear(value) {
      this.offCyclePay.year = value;
      this.offCycleQuery = `?year=${value}&filter=${this.offCyclePay.type}`;
      this.getOffCycle("");
    },
    selectOffCyclePayOptions(value) {
      this.offCyclePay.type = value;
      this.offCycleQuery = `?year=${this.offCyclePay.year}&filter=${value}`;
      this.getOffCycle("");
    },
    selectGenderPayGapOptions(value) {
      this.genderPayGap.type = value;
      this.genderPayGapQuery = `?year=${this.genderPayGap.year}&type=${value}`;
      this.getGenderPayGap();
    },
    selectGenderPayGapYear(value) {
      this.genderPayGap.year = value;
      this.genderPayGapQuery = `?year=${value}&type=${this.genderPayGap.type}`;
      this.getGenderPayGap();
    },

    getPayItems() {
      this.loadingPayItem = true;
      this.payItemsData = [];
      this.payItemsLabel = [];
      this.payItemsLegend = [];
      const bgColor = [
        "#114FF5",
        "#F7B538",
        "#08633B",
        "#F15A29",
        "#080056",
        "#E99323",
        "#Fc9576",
        "#AB6D13",
        "#762C37",
        "#58d5fe",
        "#39b3f5",
        "#75706c",
        "#7a9d0b",
        "#1723d4",
        "#a3a2f4",
        "#7cbdfd",
        "#f4d021",
        "#7b36f",
        "#f87ad5",
        "#878ffe",
        "#d2207c",
      ];
      let sum = 0;

      this.$_getPayItemsAnalytics(this.itemQuery)
        .then((response) => {
          this.payItemsLabel = response.data.payItems.map(
            (items) => items.nameOnPayslip
          );
          this.payItemsData = response.data.payItems.map(
            (items) => items.rateValue
          );

          this.payItemsDataObj = {
            labels: [...this.payItemsLabel],
            datasets: [
              {
                backgroundColor: [...bgColor],
                offset: 30,
                data: [...this.payItemsData],
              },
            ],
          };

          this.payItemsData.forEach((item) => {
            sum += Number(item);
          });

          this.totalPayItems = Math.round(sum);

          this.payItemsLabel.forEach((label, i) => {
            this.payItemsLegend.push({
              color: bgColor[i],
              name: label,
              data: this.payItemsData[i],
            });
          });
          this.loadingPayItem = false;
        })
        .catch(() => {
          this.loadingPayItem = false;
        });
    },

    payType() {
      this.loadingPayType = true;
      this.chartData = [];
      this.chartLabels = [];
      this.chartLegend = [];
      this.employeesCount = [];
      const bgColor = {
        0: "#114FF5",
        1: "#F7B538",
        2: "#F15A29",
      };

      this.$_getPayTypeAnalytics(this.query)
        .then((response) => {
          this.totalPayType = this.convertToCurrency(
            response.data.grandTotalCost
          );
          const firstUpperCase = Object.keys(
            response.data.payFrequencyTotals
          ).map((value) => {
            const capitalizedFirst = value.charAt(0).toUpperCase();
            const rest = value.slice(1).toLowerCase();
            return capitalizedFirst + rest;
          });

         const labels = firstUpperCase.map((value) => value.replace(/_/g,' '));

          this.chartLabels = labels;

          Object.keys(response.data.payFrequencyTotals).forEach((value) => {
            this.chartData.push(
              response.data.payFrequencyTotals[value].totalCost.toFixed(2)
            );
            this.employeesCount.push(
              response.data.payFrequencyTotals[value].numberOfEmployees
            )
          });

          if(this.chartData.every(item => Math.round(item) === 0)) {
            this.chartLabels = [];
          }
          else {
          this.chartDataObject = {
            labels: [...this.chartLabels],
            datasets: [
              {
                backgroundColor: ["#114FF5", "#F7B538", "#F15A29"],
                offset: 30,
                data: [...this.chartData],
              },
            ],
          };

          labels.forEach((label, i) => {
            this.chartLegend.push({
              color: bgColor[i],
              name: label,
              numberEmployees: this.employeesCount[i],
              data: `${this.convertToCurrency(this.chartData[i])}`,
            });
          });
          }

          this.loadingPayType = false;
        })
        .catch(() => {
          this.loadingPayType = false;
        });
    },

    payCosts() {
      this.loadingPayCost = true;
      this.costLabels = [];
      this.costData = [];
      let sum = 0;

      this.$_getPayCostAnalytics(this.costQuery)
        .then((response) => {
          const keyLabels = Object.keys(response.data).map((value) => {
            const splitValue = value.split("-");
            return splitValue[0];
          });

          for (let i = 0; i < keyLabels.length; i++) {
            this.costLabels.push(
              this.optionsMonth.find(
                (value) => value.id === Number(keyLabels[i])
              ).name
            );
          }

          Object.keys(response.data).forEach((value) => {
            this.costData.push(response.data[value].totalAmount);
            sum += response.data[value].totalAmount;
          });

          this.totalCost = this.convertToCurrency(sum);

          const bgColor = [];

          for (let i = 0; i < this.costLabels.length; i++) {
            bgColor.push("#2176FF");
          }

          this.costChartData = {
            labels: [...this.costLabels],
            datasets: [
              {
                backgroundColor: [...bgColor],
                data: [...this.costData],
              },
            ],
          };

          this.loadingPayCost = false;
        })
        .catch(() => {
          this.loadingPayCost = false;
        });
    },

    getGenderPayGap() {
      this.loadingGenderPay = true;
      this.genderPayGapLabels = [];
      this.genderPayGapData = [];
      this.totalPayGap = "";

      this.$_getPayGenderGapAnalytics(this.genderPayGapQuery)
        .then((response) => {
          const keyLabels = Object.keys(response.data).map((value) => {
            const splitValue = value.split("-");
            return splitValue[0];
          });

          for (let i = 0; i < keyLabels.length; i++) {
            this.genderPayGapLabels.push(
              this.optionsMonth.find(
                (value) => value.id === Number(keyLabels[i])
              ).name
            );
          }

          const maleData = [];
          const femaleData = [];

          Object.keys(response.data).forEach((value) => {
            maleData.push(
              response.data[value].male
                ? Number(response.data[value].male.totalCost).toFixed(2)
                : 0.0
            );
            femaleData.push(
              response.data[value].female
                ? Number(response.data[value].female.totalCost).toFixed(2)
                : 0.0
            );
          });

          let maleSum = 0;
          let femaleSum = 0;

          maleData.forEach((amount) => {
            maleSum += Number(amount);
          });

          femaleData.forEach((amount) => {
            femaleSum += Number(amount);
          });

          this.totalPayGap = maleSum + femaleSum;

          const maleBgColor = [];
          const femaleBgColor = [];

          for (let i = 0; i < this.genderPayGapLabels.length; i++) {
            maleBgColor.push("#F15A29");
            femaleBgColor.push("#2176FF");
          }

          this.genderPayGapDataObj = {
            labels: [...this.genderPayGapLabels],
            datasets: [
              {
                label: "Female",
                backgroundColor: [...femaleBgColor],
                data: [...femaleData],
              },
              {
                label: "Male",
                backgroundColor: [...maleBgColor],
                data: [...maleData],
              },
            ],
          };
          this.loadingGenderPay = false;
        })
        .catch(() => {
          this.loadingGenderPay = false;
        });
    },

    getOffCycle() {
      this.loadingOffCyclePay = true;
      this.offCyclePayLabels = [];
      this.offCyclePayData = [];

      this.$_getOffCyclePayAnalytics(this.offCycleQuery)
        .then((response) => {
          const responseData = response.data.data;
          if (responseData) {
            responseData.forEach((data) => {
              const month = [];
              month.push(data.month);

              for (let i = 0; i < month.length; i++) {
                this.offCyclePayLabels.push(
                  this.optionsMonth.find(
                    (value) => value.id === Number(month[i])
                  ).name
                );
              }
              this.offCyclePayData.push(
                data.totalCost ? data.totalCost.toFixed(2) : 0.0
              );
            });

            let sum = 0;
            this.offCyclePayData.forEach((data) => {
              sum += Number(data);
            });

            this.totalOffCycleData = sum;

            const bgColor = [];

            for (let i = 0; i < this.offCyclePayLabels.length; i++) {
              bgColor.push("#E99323");
            }

            this.offCyclePayDataObj = {
              labels: [...this.offCyclePayLabels],
              datasets: [
                {
                  backgroundColor: [...bgColor],
                  data: [...this.offCyclePayData],
                },
              ],
            };

            this.loadingOffCyclePay = false;
          }
        })
        .catch(() => {
          this.loadingOffCyclePay = false;
        });
    },

    filterView(type) {
      this.filterTable = true;
      this.queryType = type;
    },

    closeFilter(val) {
      if (val) {
        this.filterTable = false;
      }
    },

    searchFilter(params) {
      if (this.queryType === "offCyclePay") {
        this.offCycleQuery = `?year=${this.offCyclePay.year}&filter=${this.offCyclePay.type}${params}`;
        this.getOffCycle();
      } else if (this.queryType === "genderPayGap") {
        this.genderPayGapQuery = `?year=${this.genderPayGap.year}&type=${this.genderPayGap.type}${params}`;
        this.getGenderPayGap();
      } else if (this.queryType === "payrollCost") {
        this.costQuery = `?year=${this.payrollCost.year}&type=${this.payrollCost.type}${params}`;
        this.payCosts();
      }
      this.filterTable = false;
    },

    getAllFunctions() {
      this.$_getFunction().then((response) => {
        response.data.functions.map((functionVal) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Function(s)") {
              filter.optionsBox.push({
                id: functionVal.id,
                name: functionVal.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllDesignations() {
      this.$_getDesignations("").then((response) => {
        response.data.designations.map((designation) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Designation(s)") {
              filter.optionsBox.push({
                id: designation.id,
                name: designation.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLocations() {
      this.$_getHumanarLocations("").then((response) => {
        response.data.outlets.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Location(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
          });
          return {};
        });
      });
    },

    getAllLevels() {
      this.$_getLevels().then((response) => {
        response.data.levels.map((outlet) => {
          this.filterArray.forEach((filter) => {
            if (filter.header === "Job Level(s)") {
              filter.optionsBox.push({
                id: outlet.id,
                name: outlet.name,
              });
            }
            if (filter.header === "Job Category(s)") {
              filter.optionsBox.push({
                id: outlet.category.id,
                name: outlet.category.name,
              });
            }
          });
          return {};
        });
      });
    },
  },

  mounted() {
    this.currentYear = new Date().getFullYear();
    for (let i = 0; i <= 3; i++) {
      this.optionsYear.push(this.currentYear - i);
    }
    this.getPayItems();
    this.payType();
    this.payCosts();
    this.getOffCycle();
    this.getGenderPayGap();
    this.getAllFunctions();
    this.getAllDesignations();
    this.getAllLocations();
    this.getAllLevels();
  },
};
</script>

<style>
.date:focus {
  outline: none !important;
}
.select-css span select {
  border: 0px !important;
}
</style>
